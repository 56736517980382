import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const BioModalLink = styled.div`
  cursor: pointer;
  &:hover {
    color: ${Theme.colors.lightBlue};
  }
`

export default ({ member: { bio, name, subtitle, year, years }, toggle }) => <Fragment>
  <div>
    <BioModalLink onClick={e =>
      toggle(name, subtitle, bio)
    }><i className='fa fa-crosshairs' />  {year && `${year} - `}{name}{years && ` (${years})`}</BioModalLink>
  </div>
</Fragment>
