import styled from 'styled-components'
import { Link } from 'gatsby'
import { Theme } from '@pga/pga-component-library'

const { primary, blueGrey, gold, primaryShadow } = Theme.colors

export const HeaderLink = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  font-family: ${Theme.fonts.Montserrat};
  line-height: 1.75;
  padding: 20px;
  display: block;
  position: relative;
  text-transform: capitalize;
  background-color: ${gold};
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
`

export const Wrapper = styled.div`
  border-top: 1px solid ${Theme.colors.darkgrey};
`

export const Container = styled.div`
  box-shadow: 0 5px 10px 0 ${primaryShadow};
  @media (max-width:  991.98px) {
    margin-bottom: 20px;
  }
`

export const ParentLink = styled(Link)`
  font-weight: ${props => props.active ? '700' : '600'};
  color: ${props => props.active ? primary : blueGrey};
  font-family: ${Theme.fonts.Montserrat};
  line-height: 1.75;
  padding: 15px 25px;
  font-size: 16px;
  text-transform: capitalize;
  &:hover {
    text-decoration: none;
  }
`

export const ChildLink = styled(Link)`
  border: 0;
  color: ${blueGrey};
  font-weight: 500;
  font-size: 14px;
  font-family: ${Theme.fonts.Montserrat};
  line-height: 1.5;
  padding: 11px 30px;
  text-transform: capitalize;
  display: block;
  background-color: #fff;
  padding-left: 3.5rem;
  font-weight: ${props => props.active && '700'};
  transition: .15s all;
  &:hover {
    background-color: ${Theme.colors.lightgrey};
    color: black;
    text-decoration: none;
  }
`

export const LinkWrapper = styled.div`
  background-color: ${props => props.active ? Theme.colors.lightgrey : 'white'};
  border-left: ${props => props.active ? `5px ${primary} solid` : 'none'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  &:hover {
    a {
      color: black;
    }
  }
`

export const ClickableIcon = styled.i`
  padding: 1rem;
  margin: -1rem;
  cursor: pointer;
  font-size: 1.5rem !important;
  font-weight: 500;
`
