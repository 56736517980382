import { sortWith, ascend, ifElse, pipe, T, length, lt, path, pathOr, toPairs, sortBy, nth, fromPairs } from 'ramda'
import { parseSlug } from '../../utils'
import labelRenames from '../../../../_data/labelRenames'
import uppercaseKeywords from '../../../../_data/uppercase-keywords'

export const slugify = (text = '') => {
  return text.toString().toLowerCase().trim().replace(/&/g, '-and-').replace(/[\s\W-]+/g, '-')
}

export const ignoreIndex = ({ node }) => !(node.fileAbsolutePath.includes('index.md'))

const indexOrFirst = (label, defaultValue) => (items = []) => {
  const indexMd = items.find(o => o.node.fileAbsolutePath.includes('index.md'))
  return indexMd
    ? pathOr(defaultValue, ['node', 'frontmatter', label], indexMd)
    : pathOr(defaultValue, ['0', 'node', 'frontmatter', label], items)
}

export const getParentSidebarLabel = items => indexOrFirst('sidebar_label')(items)

export const labelFormatter = (text = '') => {
  if (labelRenames[text]) return labelRenames[text]
  const result = text
    .split('-')
    .map(word => uppercaseKeywords.includes(word) ? word.toUpperCase() : word)
    .join('-')
    .replace(/-/g, ' ')
  return result
}

export const sortItemsByField = (field = 'order_number') => sortWith([
  ascend(({ node }) => node.frontmatter[field])
])

export const calcShowChildLinks = ifElse(
  arr => arr.length > 1,
  T,
  pipe(
    path(['0', 'node', 'fields', 'slug']),
    parseSlug,
    length,
    lt(2)
  )
)

/**
 * Sorts object based on prop
 const data = { 'first-plan': [1], 'third-plan': [3], 'second-plan': [2] }
 const correctOrder = [ 'first-plan', 'second-plan', 'third-plan' ]
 sortCategories (obj, activeCategory) -> { 'first-plan': [1], 'second-plan': [2], 'third-plan': [3] }
 */

export const sortCategories = pipe(
  toPairs,
  sortBy(pipe(nth(1), indexOrFirst('order_number', 10000))),
  fromPairs
)
