import { groupBy, omit, compose } from 'ramda'
import { parseSlug } from './index'

const nodesBySubCategory = groupBy(edge => edge.node.fields.subCategory)

export const processEdges = compose(
  omit(['null']),
  nodesBySubCategory
)

export const getMarkdownCreatedPageData = ({ data, pageContext }) => {
  const { category, html, frontmatter, slug } = pageContext
  const subCategoryList = processEdges(data.allMarkdownRemark.edges)

  return {
    category,
    html,
    frontmatter,
    slug,
    subCategoryList
  }
}

export const getStatefulCreatedPageData = ({ data, pageContext, location }) => {
  const [category] = parseSlug(location.pathname)
  const { edges } = data.categoryMarkdowns
  const subCategoryList = processEdges(edges)
  return {
    category,
    frontmatter: {},
    slug: location.pathname,
    subCategoryList
  }
}
