import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Member, President, Professional } from '../../components/HallOfFame/ListTypes'

const YearWrapper = styled.div`
  margin: 10px 0px;
`

const getYearId = year => `year-${year}`

export default ({ data: { members, presidents, professionals, year }, toggle }) => {
  const yearId = getYearId(year)
  return (
    <Fragment>
      <hr className='history' />
      <h2 className='table-heading' id={yearId}>{year}</h2>
      <YearWrapper>
        {members ? <Member members={members} toggle={toggle} /> : null}
        {presidents ? <President presidents={presidents} toggle={toggle} /> : null}
        {professionals ? <Professional professionals={professionals} toggle={toggle} /> : null}
      </YearWrapper>
    </Fragment>
  )
}
