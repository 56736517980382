import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../../layouts/base-with-sidebar'
import HallOfFameHeaderText from '../../components/HallOfFame/HallOfFameHeaderText'
import HallOfFameSelect from '../../components/HallOfFame/HallOfFameSelect'
import Year from '../../components/HallOfFame/Year'
import HallOfFameModal from '../../components/HallOfFame/HallOfFameModal'

export const { data: hallOfFameData } = require('../../../../_data/hall-of-fame.json')

const Wrapper = styled.div`
  @media(max-width: 575.98px) {
    flex-direction: column;
    align-items: center;
  }
`

const useUpdateModal = () => {
  const [modalState, updateModal] = useState({ modal: false, modalName: '', modalSubTitle: '', modalBio: '' })
  const toggleModal = (modalName = '', modalSubTitle = '', modalBio = '') => {
    updateModal({ modal: !modalState.modal, modalName, modalSubTitle, modalBio })
  }
  return [modalState, toggleModal]
}

export default ({ pageContext, location, data }) => {
  const [modalState, toggleModal] = useUpdateModal()

  return (
    <Layout {...{ location, pageContext, data }}>
      <h2 className='layout-title'>PGA of America Hall of Fame</h2>
      <Wrapper>
        <HallOfFameHeaderText />
        <HallOfFameSelect hallOfFameData={hallOfFameData} />
        <div className='history-hall'>
          {hallOfFameData.map((hofData, i) => <Year data={hofData} key={i} toggle={toggleModal} />)}
        </div>
      </Wrapper>
      <HallOfFameModal
        toggleClose={toggleModal}
        isOpen={modalState.modal}
        modalName={modalState.modalName}
        modalSubTitle={modalState.modalSubTitle}
        modalBio={modalState.modalBio}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/history/hall-of-fame/"
          }
        }
      }
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
            category
            subCategory
            article
          }
          frontmatter {
            title
            sidebar_label
            order_number
          }
        }
      }
    }
    categoryMarkdowns: allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            regex: "/history/"
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
  }
`
