import React, { Fragment } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import ModalLink from './ModalLink'

const MemberWrapper = styled.div`
  margin: 0px 0px 10px 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr); 
  @media(max-width: 575.98px) {    
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr); 
  }
`
const PresidentWrapper = styled(MemberWrapper)`
  margin-bottom: 30px;
`
export const Member = ({ members, toggle }) => <MemberWrapper>
  {members.map((member, i) => {
    if (!isEmpty(member.bio)) {
      return (
        <ModalLink member={member} toggle={toggle} key={i} />
      )
    } else {
      return <div key={i}>{member.name}</div>
    }
  })}
</MemberWrapper>

export const President = ({ presidents, toggle }) => <Fragment>
  <h4>Past Presidents</h4>
  <PresidentWrapper>
    {presidents.map((president, i) => {
      if (!isEmpty(president.bio)) {
        return (
          <ModalLink member={president} toggle={toggle} key={i} />
        )
      } else {
        return <div key={i}>{president.name} ({president.years})</div>
      }
    })}
  </PresidentWrapper>
</Fragment>

export const Professional = ({ professionals, toggle }) => <Fragment>
  <h4>PGA Golf Professionals of the Year</h4>
  <MemberWrapper>
    {professionals.map((professional, i) => {
      if (!isEmpty(professional.bio)) {
        return (
          <ModalLink member={professional} toggle={toggle} key={i} />
        )
      } else {
        return <div key={i}>{professional.year} - {professional.name}</div>
      }
    })}
  </MemberWrapper>
</Fragment>
