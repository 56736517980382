import React from 'react'

const getYearId = year => `year-${year}`

const yearSelectHandler = (e) => {
  window.location = e.target.value
}

export default ({ hallOfFameData }) => <select className='form-control select-narrow' onChange={yearSelectHandler}>
  <option>Jump to a Year</option>
  {hallOfFameData.map((hofData, i) => {
    const yearId = `#${getYearId(hofData.year)}`
    return (
      <option value={yearId} key={i}>
        {hofData.year}
      </option>
    )
  }
  )}
</select>
