import React, { useState, useRef } from 'react'
import { Collapse } from 'reactstrap'
import propTypes from 'prop-types'
import { parseSlug } from '../../utils'
import { ParentLink, ChildLink, LinkWrapper, HeaderLink, Container, ClickableIcon, Wrapper } from './styled'
import { sortItemsByField, labelFormatter, sortCategories, calcShowChildLinks, getParentSidebarLabel, ignoreIndex } from './utils'

export const AccordionParent = ({ active, parent, subCategory, showChildLinks, isCollapsed, setCollapse, childLinks }) => (
  <LinkWrapper {...{ active }}>
    <ParentLink to={`/${parent}/${subCategory}`} {...{ active }}>
      {getParentSidebarLabel(childLinks) ? getParentSidebarLabel(childLinks) : labelFormatter(subCategory)}
    </ParentLink>

    {
      showChildLinks ? (
        isCollapsed
          ? <ClickableIcon onClick={() => setCollapse(!isCollapsed)} className='fa fa-angle-up' />
          : <ClickableIcon onClick={() => setCollapse(!isCollapsed)} className='fa fa-angle-down' />
      ) : null
    }
  </LinkWrapper>
)

export const AccordionChildren = ({ showChildLinks, isCollapsed, childLinks, activeArticle }) => (
  showChildLinks
    ? (
      <Collapse isOpen={isCollapsed}>
        {
          sortItemsByField('order_number')(childLinks)
            .map((child, idx) => {
              const { fields, frontmatter } = child.node
              const active = activeArticle === fields.article
              return (
                <ChildLink to={fields.slug} active={active} key={idx}>{frontmatter.sidebar_label || frontmatter.title}</ChildLink>
              )
            })
        }
      </Collapse>
    ) : null
)

export const renderItems = ({ sortedItems, parent, activeSubCategory, activeArticle }) => {
  return Object.keys(sortedItems)
    .map((subCategory, i) => {
      const childLinks = sortedItems[subCategory]
      const childLinksNoIndex = sortedItems[subCategory].filter(ignoreIndex)

      const showChildLinks = calcShowChildLinks(childLinks)
      const active = subCategory === activeSubCategory
      const initial = useRef(true)
      const [isCollapsed, setCollapse] = useState(false)

      if (active && initial.current) {
        setCollapse(true)
        initial.current = false
      }

      return (
        <Wrapper key={i}>
          <AccordionParent {...{ active, parent, subCategory, childLinks, showChildLinks, isCollapsed, setCollapse }} />
          <AccordionChildren {...{ showChildLinks, isCollapsed, childLinks: childLinksNoIndex, activeArticle }} />
        </Wrapper>
      )
    })
}

const Accordion = ({ items = {}, parent = '', slug }) => {
  const [activeCategory, activeSubCategory, activeArticle] = parseSlug(slug) // eslint-disable-line
  const sortedItems = sortCategories(items)

  return (
    <Container>
      <HeaderLink to={`/${parent}`}>{labelFormatter(parent)}</HeaderLink>
      {renderItems({ sortedItems, parent, activeSubCategory, activeArticle })}
    </Container>
  )
}

Accordion.propTypes = {
  items: propTypes.object.isRequired,
  slug: propTypes.string.isRequired,
  parent: propTypes.string.isRequired
}

export default Accordion
