import React from 'react'
import { Row, Col } from 'reactstrap'

const hasLocation = ({ city, state, zip }) => city && state && zip
const hasEmail = ({ email }) => email

const ConsultantCard = ({ item }) => (
  <Col lg={4} md={6}>
    <div className='career-consultant-tiles career-consultant-tiles-square'>
      <div className='career-consultant-tiles-image'>
        <img src={item.image} alt={item.name} />
      </div>
      <div className='career-consultant-tiles-body'>
        <div className='name'>{item.name}</div>
        {hasLocation(item) && <p className='text-muted lead mb-0'>{item.city}, {item.state} {item.zip}</p>}
        <p className='text-muted lead mb-0'>{item.phone}</p>
        {hasEmail(item) && <a className='email' href={`mailto:${item.email}`}>{item.email}<i className='icon icon-arrow-right' /></a>}
        <p className='lead mb-0'>
          <strong>
            {Array.isArray(item.sections) ? item.sections.join('; ') : item.sections}
          </strong>
        </p>
      </div>
    </div>
  </Col>
)

export default ({ items }) => (
  <Row>
    {items
      ? items.map((item, i) => <ConsultantCard key={i} item={item} />)
      : null}
  </Row>
)
