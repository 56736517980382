import React, { Fragment } from 'react'

export default () => <Fragment>
  <p>The PGA of America Hall of Fame originated in 1940 at the suggestion of famed sportswriter Grantland Rice.     It is the highest honor that the PGA of America can bestow upon its membership or ambassadors of golf.</p>
  <p>Most of the original inductees were later enshrined at the PGA World Golf Hall of Fame in Pinehurst, North Carolina.</p>
  <p>In 1993, the PGA of America ceased PGA World Golf Hall of Fame operations in Pinehurst and subsequently relocated to the World Golf Hall of Fame in St. Augustine, Florida.</p>
  <div className='collapse' id='collapseCopy'>
    <p>While all of the Pinehurst-enshrined members were transferred to the new World Golf Hall of Fame, there were some members of the PGA Hall of Fame who were not recognized at the facility in World Golf Village.</p>
    <p>In 2002, the PGA opened the PGA Historical Center [later the PGA Museum of Golf] at PGA Village in Port St. Lucie, Florida. This paved the way for the first home for the PGA Hall of Fame. The inaugural ceremony was conducted Sept. 8, 2005, as the Association recognized all PGA Members who have made significant and lasting contributions to building the PGA of America and the game of golf.</p>
    <p>In 2015, the PGA of America reinstituted its original Hall of Fame requirements to include non-PGA Members who have served as ambassadors of golf.</p>
    <p>In December 2015, the PGA Museum of Golf ceased operations, with the PGA Hall of Fame’s new location in transition.</p>
  </div>
  <p><a aria-controls='collapseCopy' aria-expanded='false' data-toggle='collapse' href='#collapseCopy' id='toggle-expand'><span className='readMore'>Read More <i className='fa fa-angle-down' /></span><span className='readLess'>Read Less <i className='fa fa-angle-up' /></span></a></p>
  <h3>PGA of America Hall of Fame Members (including year of induction)</h3>
</Fragment>
