import styled from 'styled-components'

export const Heading = styled.h2`
  font-size: 40px;
  margin-bottom: 1.5rem;
  font-weight: 500;
  @media (max-width: 991.98px) {
    font-size: 32px;
  }
`
