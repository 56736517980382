import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { LinkIcon } from '@pga/pga-component-library/lib/components/Icons'

const Wrapper = styled.div`
  padding: 30px;
  background: ${Theme.colors.lightBgGrey};
`

const Heading = styled.h3`
  border-right: 1px solid ${Theme.colors.blueGrey};
  padding-right: 1rem;
`
const Text = styled.p`
  padding-left: 1rem;
  font-weight: 600;
`
const Link = styled.a`
  font-size: 14px;
  i {
    padding: 0;
  }
`
const ImageWrapper = styled.div.attrs({
  className: 'col-lg-4 col-md-4 col-sm-12'
})`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767.98px) {
    justify-content: center;
    order: -1;
    margin-bottom: 1rem;
  }
`

const Item = ({ item: { name, label, list, link, image }, length, i }) => (
  <Fragment>
    <div className="d-flex align-items-center">
      <Heading>{name}</Heading>
      <Text className='text-success'>{label}</Text>
    </div>
    <div className="row">
      <div className="col-lg-8 col-md-8 col-sm-12">
        <ul>
          {list.map((listItem, idx) => (
            <li key={idx}>{listItem}</li>
          ))}
        <Link href={link}>Learn More <LinkIcon /> </Link>
        </ul>
      </div>
      <ImageWrapper>
        <a href={link}>
          <img className='img-fluid' src={image} alt={name} />
        </a>
      </ImageWrapper>
    </div>
    
    {length - 1 > i && <hr />}
  </Fragment>
)

export default ({ items }) => (
  <Wrapper>
    {items
      ? items.map((item, i) => <Item key={i} item={item} i={i} length={items.length} />)
      : null}
  </Wrapper>
)
