import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

const StyledModal = styled(Modal)`
  @media (min-width:  576px) {
    max-width: 750px !important;
  }
`
const ModalTitle = styled.div`
  margin: 10px 0px 10px 10px;
  color: ${Theme.colors.primary};
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.09;
  letter-spacing: -0.3px;
  font-family: ${Theme.fonts.PlayfairDisplay};
`
const ModalSubTitle = styled.div`
  color: ${Theme.colors.primary};
  font-size: 20px;
  margin-top: 15px;
`
const StyledModalBody = styled(ModalBody)`
  padding: 1.7rem !important;
`

export default ({ isOpen, toggleClose, modalName, modalSubTitle, modalBio }) => {
  return (
    <StyledModal isOpen={isOpen} toggle={() => toggleClose()}>
      <ModalHeader toggle={() => toggleClose()}>
        <ModalTitle>
          {modalName}
          {modalSubTitle && <ModalSubTitle>{modalSubTitle}</ModalSubTitle>}
        </ModalTitle>
      </ModalHeader>
      <StyledModalBody dangerouslySetInnerHTML={{ __html: modalBio }} />
    </StyledModal>
  )
}
